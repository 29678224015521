import React from "react";
import banner from "../../assets/images/Banner.png"; // Ensure the path to the banner is correct

const Landing = () => {
  return (
    <div
    id="contact-landing"
      className="relative h-[35vh] bg-cover bg-center"
      style={{ backgroundImage: `url(${banner})` }}
    >
      {/* Overlay Text */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
        <h1 className="text-[32px] md:text-[72px] font-semibold" style={{color: '#e1002a', fontFamily: 'Quantum rise'}}>Contact Us</h1>
        <p className="text-xl mt-2" style={{color: '#cfd0d2'}}>We would love to hear from you</p>
      </div>
    </div>
  );
};

export default Landing;
