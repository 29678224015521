import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./Map.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2hpdmFta3NpbmhhMTEiLCJhIjoiY2ttZXp1bnZhMG14MzJubno0MTY3N2U4eCJ9.NiKHmUzqfICTwkAyCP0RaQ";

const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v11",
      center: [72.878360, 19.202510],
      zoom: 15,
    });

    // Create a red marker like the one in the image
    new mapboxgl.Marker({
      color: "#E41636", // Red color for the marker
    })
      .setLngLat([72.878360, 19.202510]) // Longitude and latitude for the marker
      .addTo(map.current);
  }, []);

  return (
    <div className="relative w-full h-[350px]">
      <div ref={mapContainer} className="map-container" />
    </div>
  );
};

export default Map;
