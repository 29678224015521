import React from "react";
import automotive from "../../assets/images/Automotive.png";
import farmEquipment from "../../assets/images/Farm Equipment.png";
import financialServices from "../../assets/images/Financial Services.png";
import renewableEnergy from "../../assets/images/Renewable Energy.png";
import hospitality from "../../assets/images/Hospitality.png";
import realEstate from "../../assets/images/Real Estate.png";
import carNBike from "../../assets/images/Car & Bike.png";
import accelo from "../../assets/images/Accelo.png";

const IndustrySolutions = () => {
  const industries = [
    { name: "Automotive", image: automotive, link: "#" },
    { name: "Farm Equipment", image: farmEquipment, link: "#" },
    {
      name: "Financial Services",
      image: financialServices,
      link: "#",
    },
    {
      name: "Renewable Energy",
      image: renewableEnergy,
      link: "#",
    },
    { name: "Hospitality", image: hospitality, link: "#" },
    { name: "Real Estate", image: realEstate, link: "#" },
    {
      name: "Car & Bike",
      image: carNBike,
      link: "#",
    },
    {
      name: "Accelo",
      image: accelo,
      link: "#",
    },
  ];

  return (
    <div className="py-16 space-y-10">
      <div className="px-5 md:px-24 mb-8 lg:mb-0 xl:w-4/5 text-center md:text-left">
        <h2
          className="text-sm font-thin uppercase my-4 md:my-7"
          style={{ color: "#dd052b" }}
        >
          About
        </h2>
        <h1 className="text-[32px] md:text-[56px] font-bold leading-14" style={{fontFamily: 'Quantum rise'}}>
          Industry Specific Solutions
        </h1>
      </div>
      <div className="mx-auto md:mx-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-5 md:px-16">
        {industries.map((industry, index) => (
          <a
            key={index}
            href={industry.link}
            className="group relative block overflow-hidden rounded-md shadow-lg"
          >
            {/* Image */}
            <img
              src={industry.image}
              alt={industry.name}
              className="transform group-hover:scale-110 transition-transform duration-500 ease-in-out w-full h-full object-cover"
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#E41636] opacity-0 group-hover:opacity-50 transition-opacity duration-500"></div>
            <div className="absolute inset-0 bottom-8 px-6 flex items-end z-10">
              <h3 className="text-white text-lg font-thin">{industry.name}</h3>
            </div>
          </a>
        ))}
      </div>
      <div className="px-5 md:px-24 py-10">
        <hr />
      </div>
    </div>
  );
};

export default IndustrySolutions;
