// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Georama_SemiExpanded/Georama_SemiExpanded/Georama_SemiExpanded-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/QuantumRise/QuantumRise-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Georama SemiExpanded";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Quantum rise";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___})
    format("truetype");
  font-weight: normal;
  font-style: normal;
}


::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Georama SemiExpanded", sans-serif;
}

html {
  scroll-behavior: smooth;
}


/* coming soon page */
.transition-text {
  display: inline-block;
}

.char {
  color: gray;
  transition: color 0.5s ease; /* Smooth color transition */
}

.char.active {
  color: #E41636;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC;sBACoB;EACpB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B;sBACoB;EACpB,mBAAmB;EACnB,kBAAkB;AACpB;;;AAGA;EACE,aAAa;AACf;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,uBAAuB;AACzB;;;AAGA,qBAAqB;AACrB;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,2BAA2B,EAAE,4BAA4B;AAC3D;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@font-face {\n  font-family: \"Georama SemiExpanded\";\n  src: url(\"./assets/fonts/Georama_SemiExpanded/Georama_SemiExpanded/Georama_SemiExpanded-Medium.ttf\")\n    format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Quantum rise\";\n  src: url(\"./assets/fonts/QuantumRise/QuantumRise-Bold.otf\")\n    format(\"truetype\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n\n::-webkit-scrollbar {\n  display: none;\n}\n\nbody {\n  font-family: \"Georama SemiExpanded\", sans-serif;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n\n/* coming soon page */\n.transition-text {\n  display: inline-block;\n}\n\n.char {\n  color: gray;\n  transition: color 0.5s ease; /* Smooth color transition */\n}\n\n.char.active {\n  color: #E41636;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
