// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Landing page styles */

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .landing-content {
    padding: 100px 50px;
    z-index: 2;
  }
  
  .cta-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: 2px solid #e1002a;
    background-color: transparent;
    color: #e1002a;
    font-family: "Georama", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e1002a;
    color: white;
  }
  
  
  @keyframes grow-border {
    from {
      width: 0;
    }
    to {
      width: 50%; /* Animates to 50% of the div’s width */
    }
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .slant-section {
      clip-path: polygon(0 0, 100% 0, 100% 40%, 0 80%); /* Mobile slant */
    }
  
    .landing-content {
      padding: 50px 20px; /* Adjust padding for smaller screens */
    }
  
    .cta-button {
      font-size: 0.875rem; /* Slightly smaller font size on mobile */
      padding: 8px 16px; /* Adjust padding for smaller screens */
    }
  }
  
  /* About styles */
  
  `, "",{"version":3,"sources":["webpack://./src/components/Join/Join.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,mBAAmB;IACnB,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;IACd,kCAAkC;IAClC,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;;EAGA;IACE;MACE,QAAQ;IACV;IACA;MACE,UAAU,EAAE,uCAAuC;IACrD;EACF;;EAEA,kBAAkB;EAClB;IACE;MACE,gDAAgD,EAAE,iBAAiB;IACrE;;IAEA;MACE,kBAAkB,EAAE,uCAAuC;IAC7D;;IAEA;MACE,mBAAmB,EAAE,yCAAyC;MAC9D,iBAAiB,EAAE,uCAAuC;IAC5D;EACF;;EAEA,iBAAiB","sourcesContent":["/* Landing page styles */\n\n.background-video {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    z-index: -1;\n  }\n  \n  .landing-content {\n    padding: 100px 50px;\n    z-index: 2;\n  }\n  \n  .cta-button {\n    margin-top: 20px;\n    padding: 10px 20px;\n    border: 2px solid #e1002a;\n    background-color: transparent;\n    color: #e1002a;\n    font-family: \"Georama\", sans-serif;\n    font-size: 1rem;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .cta-button:hover {\n    background-color: #e1002a;\n    color: white;\n  }\n  \n  \n  @keyframes grow-border {\n    from {\n      width: 0;\n    }\n    to {\n      width: 50%; /* Animates to 50% of the div’s width */\n    }\n  }\n  \n  /* Mobile Styles */\n  @media (max-width: 768px) {\n    .slant-section {\n      clip-path: polygon(0 0, 100% 0, 100% 40%, 0 80%); /* Mobile slant */\n    }\n  \n    .landing-content {\n      padding: 50px 20px; /* Adjust padding for smaller screens */\n    }\n  \n    .cta-button {\n      font-size: 0.875rem; /* Slightly smaller font size on mobile */\n      padding: 8px 16px; /* Adjust padding for smaller screens */\n    }\n  }\n  \n  /* About styles */\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
