import React, { useState, useEffect, useCallback } from "react";
import handShake from "../../assets/images/Banner_Join_us.png";
import "./Join.css";

const Landing = () => {
  const [borderWidth, setBorderWidth] = useState(15); // Initial border width
  const [animate, setAnimate] = useState(false);

  // Function to handle scroll event
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;

    // Set border width based on scroll position
    if (scrollTop < viewportHeight) {
      setBorderWidth(50); // Trigger animation when within the viewport
      setAnimate(true);
    } else {
      setBorderWidth(15); // Reset border width when scrolled beyond 100vh
      setAnimate(false);
    }
  }, []);

  useEffect(() => {
    // Initial animation on page load
    setAnimate(true);

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const element = document.querySelector(".main-diagonal");
    if (animate && element) {
      // Force a reflow to restart the animation
      element.classList.remove("animate-border");
      void element.offsetWidth; // Trigger a reflow
      element.classList.add("animate-border");
    }
  }, [animate]);
  return (
    <div id="join-us-landing">
      <div className="h-[92vh] relative">
        <img src={handShake} className="h-full w-full object-cover" />
      </div>

      <div
        className="main-diagonal"
        style={{ "--border-width": `${borderWidth}%` }}
      ></div>

      <div className="flex flex-col absolute top-20 z-10 mt-14 md:mt-8 items-center px-5 md:px-0 lg:w-[60%]">
        <div className="md:px-24">
          <h1
            className="text-[32px] md:text-[72px]"
            style={{ color: "#E41636", fontFamily: 'Quantum rise' }}
          >
            Join us
          </h1>
          <p className="text-[16px] md:text-[24px] w-full md:w-[50%] lg:w-full">
            Explore oppurtunities to grow with us and make a difference in the
            AI landscape
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
