import React from "react";

const Tech = () => {
  return (
    <div className="bg-gray-100 py-16">
      <div className="flex flex-col lg:flex-row items-center justify-between md:px-24">
        {/* Left Section (Title) */}
        <div className="mb-8 lg:mb-0 lg:w-1/3 text-center lg:text-left">
          <h2
            className="text-sm font-thin uppercase my-4 md:my-5"
            style={{ color: "#dd052b" }}
          >
            About Our
          </h2>
          <h1
            className="text-[32px] md:text-[56px] font-bold"
            style={{ fontFamily: "Quantum rise" }}
          >
            Tech
          </h1>
        </div>

        {/* Right Section (Cards) */}
        <div className="lg:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Card 1 */}
          <a
            href="https://ai.mahindra.com/"
            className="w-[300px] md:w-full hover:hover:bg-[#dd052b] hover:text-white shadow-md p-8 text-center border border-gray-200 hover:shadow-lg transition-shadow"
          >
            <h3 className="text-xl font-bold mb-2">GenAI</h3>
            <div
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center"
            >
              Link to Mahindra.AI
              <span className="ml-2">↗</span>
            </div>
          </a>

          {/* Card 2 */}
          <a
            href="#"
            className="w-[300px] md:w-full hover:hover:bg-[#dd052b] hover:text-white shadow-md p-8 text-center border border-gray-200 hover:shadow-lg transition-shadow"
          >
            <h3 className="text-xl font-bold mb-2">VisionAI</h3>
            <div className="flex justify-center items-center">
              Link to Mahindra Vision.AI
              <span className="ml-2">↗</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tech;
