import React from "react";
import { useNavigate } from "react-router-dom";
import aboutImage from "../../assets/images/icon_Vision.png";
import learnMore from "../../assets/images/swipe_right.png";

const AboutMahindraAI = () => {
  const navigate = useNavigate();
  let timeoutRef;

  const handleButtonClick = () => {
    // Navigate to /about
    navigate("/about");
    // Add hash for scrolling
    timeoutRef = setTimeout(() => {
      window.location.hash = "#vision"; // Scroll to the specific section
      clearTimeout(timeoutRef)
    }, 50); // Use a timeout to give React Router time to navigate
  };
  return (
    <div className="my-16 px-5 md:px-36 flex flex-col xl:flex-row">
      {/* ABOUT */}
      <div className="w-full xl:w-[50vw] flex flex-col space-y-7">
        <p className="font-thin" style={{ color: "#dd052b" }}>
          ABOUT
        </p>
        <h1
          className="text-[32px] md:text-[56px] md:leading-[60px]"
          style={{ fontFamily: "Quantum rise" }}
        >
          Mahindra<div>AI</div>
        </h1>
        <p className="font-thin text-base">
          {" "}
          Join us as we drive innovation and shape the future with cutting-edge
          technology.
        </p>
      </div>
      <div className="w-full xl:w-[50vw] mt-10 xl:mt-0 flex flex-col items-center md:flex-row md:items-end">
        {/* Vision */}
        <div className="text-white VisonBack rounded-lg p-10 w-80 h-[300px]">
          <img src={aboutImage} alt="Vision" />
          <div className="mt-10 mb-5 text-[24px] font-semibold tracking-wide">
            Vision
          </div>
          <div className="font-thin text-sm">
            Unlock exponential value for Mahindra Group with AI
          </div>
        </div>
        {/* Learn more*/}
        <div className="rounded-b-lg md:rounded-r-lg p-10 w-80 h-[230px] border-[1px] border-slate-200 space-y-5">
          <h3 className="text-[24px] font-semibold tracking-wide">Mission</h3>
          <p className="font-thin text-sm">
            Our mission is to lead in AI innovation & transformation
          </p>
          <a
            className="flex items-center cursor-pointer"
            onClick={handleButtonClick}
          >
            <img
              src={learnMore}
              alt="Vision"
              className="w-5 h-5 bg-slate-300 rounded-full"
            />
            <div className="ml-3 font-thin text-red-500">Learn More</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutMahindraAI;
