import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion } from "framer-motion";
import Landing from '../components/Join/Landing';
import JoinTeam from '../components/Join/JoinTeam';
import PartnerWithUs from '../components/Join/PartnerWithUs';

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const Joinus = () => {
  const [animationStates, setAnimationStates] = useState({
    joinTeam: false,
    partnerWithUs: false,
  });

  const lastScrollY = useRef(0);
  const viewportHeight = useRef(window.innerHeight);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    viewportHeight.current = window.innerHeight;

    if (scrollTop > lastScrollY.current) {
      // Scroll down
      if (scrollTop + viewportHeight.current >= document.getElementById("join-team").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, joinTeam: true }));
      }
      if (scrollTop + viewportHeight.current >= document.getElementById("partner-with-us").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, partnerWithUs: true }));
      }
    } else {
      // Scroll up
      if (scrollTop + viewportHeight.current < document.getElementById("join-team").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, joinTeam: false }));
      }
      if (scrollTop + viewportHeight.current < document.getElementById("partner-with-us").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, partnerWithUs: false }));
      }
    }

    lastScrollY.current = scrollTop;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <Landing />

      <motion.div
        id="join-team"
        initial="hidden"
        animate={animationStates.joinTeam ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <JoinTeam />
      </motion.div>

      <motion.div
        id="partner-with-us"
        initial="hidden"
        animate={animationStates.partnerWithUs ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <PartnerWithUs />
      </motion.div>
    </div>
  );
};

export default Joinus;
