import React, { useState } from "react";
import vision from "../../assets/images/Vision.png";
import visionOverlay from "../../assets/images/Vision_pointer_overlay.png";
import mission from "../../assets/images/Mission.png";
import missionOverlay from "../../assets/images/Mission_pointer_Overlay.png";

const Vision = () => {
  const [activeImage, setActiveImage] = useState("");

  return (
    <div id="vision" className="flex flex-col md:flex-row justify-between mb-10 items-center md:items-start py-16 px-5 md:px-24 bg-white">
      {/* Vision Section */}
      <div className="w-full md:w-1/2 flex flex-col items-center md:items-start mb-10 md:mb-0">
        <div
          className="font-thin text-lg md:text-xl my-4 md:my-7"
          style={{ color: "#dd052b" }}
        >
          OUR
        </div>
        <h1
          className={`text-[32px] md:text-[56px] font-bold mb-4 ${
            activeImage === "vision"
              ? "text-[#dd052b] transition-all duration-1000"
              : ""
          }`}
          style={{ fontFamily: "Quantum rise" }}
        >
          Vision
        </h1>
        <p className="text-base md:text-lg mb-6 text-gray-700 text-center md:text-left">
          Unlock exponential value for Mahindra Group with AI
        </p>
        <div className="relative w-48 h-48 sm:w-64 sm:h-64">
          {/* First Image */}
          <img
            src={activeImage === "vision" ? visionOverlay : vision}
            alt="Vision Image"
            className={`absolute cursor-pointer top-0 right-12 md:right-0 md:left-0 xl:left-16 w-full h-full object-cover`}
            style={{ zIndex: activeImage === "vision" ? 10 : 6 }}
            onMouseEnter={() => setActiveImage("vision")}
            onMouseLeave={() => setActiveImage("")}
          />

          {/* Second Image (Mission) */}
          <img
            src={activeImage === "mission" ? missionOverlay : mission}
            alt="Mission Image"
            className={`absolute cursor-pointer top-8 md:left-16 sm:right-10 md:right-0 sm:top-12 sm:left-20 xl:left-48 w-full h-full object-cover`}
            style={{ zIndex: activeImage === "mission" ? 10 : 5 }}
            onMouseEnter={() => setActiveImage("mission")}
            onMouseLeave={() => setActiveImage("")}
          />
        </div>
      </div>

      {/* Mission Section */}
      <div className="w-full md:w-1/2 flex flex-col items-center md:items-start px-3">
        <div
          className="font-thin text-lg md:text-xl my-4 md:my-7"
          style={{ color: "#dd052b" }}
        >
          OUR
        </div>
        <h1
          className={`text-[32px] md:text-[56px] font-bold mb-4 ${
            activeImage === "mission"
              ? "text-[#dd052b] transition-all duration-1000"
              : ""
          }`}
          style={{ fontFamily: "Quantum rise" }}
        >
          Mission
        </h1>
        <p className="text-base md:text-lg mb-6 text-gray-700 text-center md:text-left">
          To lead in AI innovation and transformation
        </p>
        <ul className="list-none text-left space-y-6 text-gray-700 ">
          <li className="flex items-start">
            <span className="text-[#dd052b] mr-2">•</span>
            <span>
              <strong>AI Product Development</strong>: Create and monetize
              advanced AI solutions, leveraging Mahindra's ecosystem for
              superior insights and value.
            </span>
          </li>
          <li className="flex items-start">
            <span className="text-[#dd052b] mr-2">•</span>
            <span>
              <strong>Transformative AI Integration</strong>: Accelerate
              Mahindra’s digital evolution through AI and GenAI, driving
              efficiency and growth.
            </span>
          </li>
          <li className="flex items-start">
            <span className="text-[#dd052b] mr-2">•</span>
            <span>
              <strong>AI Adoption & Learning</strong>: Lower barriers to AI
              adoption and cultivate a learning culture.
            </span>
          </li>
          <li className="flex items-start">
            <span className="text-[#dd052b] mr-2">•</span>
            <span>
              <strong>Responsible AI</strong>: Prioritize ethical AI practices
              to ensure positive societal impact.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Vision;
