import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion } from "framer-motion";
import Landing from '../components/About/Landing';
import Vision from '../components/About/Vision';
import Tech from '../components/About/Tech';
import IndustrySolutions from '../components/About/IndustrySolutions';
import AboutMahindra from '../components/About/AboutMahindra';
import Leadership from '../components/About/Leadership';

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const About = () => {
  const [animationStates, setAnimationStates] = useState({
    vision: false,
    tech: false,
    industrySolutions: false,
    aboutMahindra: false,
    leadership: false,
  });

  const lastScrollY = useRef(0);
  const viewportHeight = useRef(window.innerHeight);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    viewportHeight.current = window.innerHeight;

    if (scrollTop > lastScrollY.current) {
      if (scrollTop + viewportHeight.current >= document.getElementById("vision").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, vision: true }));
      }
      if (scrollTop + viewportHeight.current >= document.getElementById("tech").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, tech: true }));
      }
      if (scrollTop + viewportHeight.current >= document.getElementById("industry-solutions").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, industrySolutions: true }));
      }
      if (scrollTop + viewportHeight.current >= document.getElementById("about-mahindra").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, aboutMahindra: true }));
      }
      if (scrollTop + viewportHeight.current >= document.getElementById("leadership").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, leadership: true }));
      }
    } else {
      if (scrollTop + viewportHeight.current < document.getElementById("vision").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, vision: false }));
      }
      if (scrollTop + viewportHeight.current < document.getElementById("tech").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, tech: false }));
      }
      if (scrollTop + viewportHeight.current < document.getElementById("industry-solutions").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, industrySolutions: false }));
      }
      if (scrollTop + viewportHeight.current < document.getElementById("about-mahindra").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, aboutMahindra: false }));
      }
      if (scrollTop + viewportHeight.current < document.getElementById("leadership").offsetTop) {
        setAnimationStates((prev) => ({ ...prev, leadership: false }));
      }
    }

    lastScrollY.current = scrollTop;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <Landing />

      <motion.div
        id="vision"
        initial="hidden"
        animate={animationStates.vision ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <Vision />
      </motion.div>

      <motion.div
        id="tech"
        initial="hidden"
        animate={animationStates.tech ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <Tech />
      </motion.div>

      <motion.div
        id="industry-solutions"
        initial="hidden"
        animate={animationStates.industrySolutions ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <IndustrySolutions />
      </motion.div>

      <motion.div
        id="about-mahindra"
        initial="hidden"
        animate={animationStates.aboutMahindra ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <AboutMahindra />
      </motion.div>

      <motion.div
        id="leadership"
        initial="hidden"
        animate={animationStates.leadership ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <Leadership />
      </motion.div>
    </div>
  );
};

export default About;
