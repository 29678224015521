// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/Vision_Home.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-diagonal {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80%;
  margin-top: 10%;
  transform: skewY(-16deg) translateY(-30%);
  transform-origin: 0 0;
  background: #fff;
}

.main-diagonal::after {
  content: '';
  position: absolute;
  bottom: 0px; /* Position it right at the bottom */
  left: 0; /* Start from the left */
  width: var(--border-width); /* Dynamic width based on state */
  height: 10px; /* Height of the border */
  background-color: #E41636; /* Red color for the border */
  transform: skewX(17deg); /* Adjust the angle of the red line to match the slant */
  transition: width 0.7s ease-in-out; /* Smooth transition for the width change */
}

.animate-border::after {
  animation: expandBorder 2s forwards; /* Apply animation */
}

@keyframes expandBorder {
  from {
    width: 15%;
  }
  to {
    width: 50%;
  }
}


/* About styles */

.VisonBack {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,MAAM;EACN,OAAO;EACP,YAAY;EACZ,WAAW;EACX,eAAe;EACf,yCAAyC;EACzC,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW,EAAE,oCAAoC;EACjD,OAAO,EAAE,wBAAwB;EACjC,0BAA0B,EAAE,iCAAiC;EAC7D,YAAY,EAAE,yBAAyB;EACvC,yBAAyB,EAAE,6BAA6B;EACxD,uBAAuB,EAAE,wDAAwD;EACjF,kCAAkC,EAAE,2CAA2C;AACjF;;AAEA;EACE,mCAAmC,EAAE,oBAAoB;AAC3D;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;;AAGA,iBAAiB;;AAEjB;EACE,yDAA0D;EAC1D,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;AAC9B","sourcesContent":[".main-diagonal {\n  position: absolute;\n  z-index: 1;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 80%;\n  margin-top: 10%;\n  transform: skewY(-16deg) translateY(-30%);\n  transform-origin: 0 0;\n  background: #fff;\n}\n\n.main-diagonal::after {\n  content: '';\n  position: absolute;\n  bottom: 0px; /* Position it right at the bottom */\n  left: 0; /* Start from the left */\n  width: var(--border-width); /* Dynamic width based on state */\n  height: 10px; /* Height of the border */\n  background-color: #E41636; /* Red color for the border */\n  transform: skewX(17deg); /* Adjust the angle of the red line to match the slant */\n  transition: width 0.7s ease-in-out; /* Smooth transition for the width change */\n}\n\n.animate-border::after {\n  animation: expandBorder 2s forwards; /* Apply animation */\n}\n\n@keyframes expandBorder {\n  from {\n    width: 15%;\n  }\n  to {\n    width: 50%;\n  }\n}\n\n\n/* About styles */\n\n.VisonBack {\n  background-image: url(../../assets/images/Vision_Home.png);\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
