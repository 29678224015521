import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion } from "framer-motion";
import LandingPage from "../components/Home/LandingPage";
import About from "../components/Home/About";
import JoinUs from "../components/Home/JoinUs";
import AboutMahindraAI from "../components/Home/AboutMahindraAI";

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const Home = () => {
  const [animationStates, setAnimationStates] = useState({
    aboutMahindraAI: false,
    about: false,
    joinUs: false
  });

  const lastScrollY = useRef(0);
  const viewportHeight = useRef(window.innerHeight);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    viewportHeight.current = window.innerHeight;

    // Check if user is scrolling down
    if (scrollTop > lastScrollY.current) {
      // Check each section's position and update animation state
      if (scrollTop + viewportHeight.current >= document.getElementById("about-mahindra-ai").offsetTop) {
        setAnimationStates(prev => ({ ...prev, aboutMahindraAI: true }));
      }
      if (scrollTop + viewportHeight.current >= document.getElementById("about").offsetTop) {
        setAnimationStates(prev => ({ ...prev, about: true }));
      }
      if (scrollTop + viewportHeight.current >= document.getElementById("join-us").offsetTop) {
        setAnimationStates(prev => ({ ...prev, joinUs: true }));
      }
    } else {
      // Check each section's position and reset animation state if scrolled back up
      if (scrollTop + viewportHeight.current < document.getElementById("about-mahindra-ai").offsetTop) {
        setAnimationStates(prev => ({ ...prev, aboutMahindraAI: false }));
      }
      if (scrollTop + viewportHeight.current < document.getElementById("about").offsetTop) {
        setAnimationStates(prev => ({ ...prev, about: false }));
      }
      if (scrollTop + viewportHeight.current < document.getElementById("join-us").offsetTop) {
        setAnimationStates(prev => ({ ...prev, joinUs: false }));
      }
    }

    lastScrollY.current = scrollTop;
  }, []);

  useEffect(() => {
    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <LandingPage />

      {/* About Mahindra AI Section */}
      <motion.div
        id="about-mahindra-ai"
        initial="hidden"
        animate={animationStates.aboutMahindraAI ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <AboutMahindraAI />
      </motion.div>

      {/* About Section */}
      <motion.div
        id="about"
        initial="hidden"
        animate={animationStates.about ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <About />
      </motion.div>

      {/* Join Us Section */}
      <motion.div
        id="join-us"
        initial="hidden"
        animate={animationStates.joinUs ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <JoinUs />
      </motion.div>
    </div>
  );
};

export default Home;
