import React from "react";
import Slider from "react-slick";
import linkedin from "../../assets/images/Media  in 2.png";
import dummyImage1 from "../../assets/images/Dummy_2.png";
import dummyImage2 from "../../assets/images/Dummy_3.png";
import dummyImage3 from "../../assets/images/Dummy_4.png";
import dummyImage4 from "../../assets/images/Dummy_5.png";
import swipeLeft from "../../assets/images/swipe_left.png";
import swipeRight from "../../assets/images/swipe_right.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const advisors = [
  { id: 1, name: "Person Name", role: "Serving Role", image: dummyImage1 },
  { id: 2, name: "Person Name", role: "Serving Role", image: dummyImage2 },
  { id: 3, name: "Person Name", role: "Serving Role", image: dummyImage3 },
  { id: 4, name: "Person Name", role: "Serving Role", image: dummyImage4 },
  { id: 5, name: "Person Name", role: "Serving Role", image: dummyImage1 },
  { id: 6, name: "Person Name", role: "Serving Role", image: dummyImage2 },
  { id: 7, name: "Person Name", role: "Serving Role", image: dummyImage3 },
];

const Leadership = () => {
  // Settings for Slick Slider
  const settings = {
    dots: true, // Enables dots for navigation
    infinite: true, // Infinite loop sliding
    speed: 500, // Transition speed
    slidesToShow: 4, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    nextArrow: <NextArrow />, // Custom next arrow
    prevArrow: <PrevArrow />, // Custom prev arrow
    responsive: [
      {
        breakpoint: 1024, // For tablets or smaller
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // For mobile phones
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-[#f4f4f4] px-4 md:px-24 space-y-10 relative pb-10">
      <div className="flex flex-col justify-center py-10 border-t">
        {/* Title */}
        <div>
          <h3 className="text-red-600 text-lg uppercase my-4 md:my-7 ">Our</h3>
          <h2
            className="text-[32px] md:text-[56px] mb-6 md:mb-10"
            style={{ fontFamily: "Quantum rise" }}
          >
            Board of Advisors
          </h2>
        </div>

        {/* Carousel using Slick */}
        <Slider {...settings}>
          {advisors.map((advisor) => (
            <div
              key={advisor.id}
              className="flex justify-center items-center px-4 py-8"
            >
              <div className="flex flex-col items-center">
                <img
                  src={advisor.image}
                  alt={advisor.name}
                  className="w-[300px] h-[300px] object-cover border-[1px] px-1 border-slate-300 rounded-md shadow-lg"
                />
                <h3 className="font-semibold text-xl mt-4">{advisor.name}</h3>
                <p className="text-gray-500">{advisor.role}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="flex flex-col justify-center">
        {/* Title */}
        <div>
          <h3 className="text-red-600 text-lg uppercase my-4 md:my-7">Our</h3>
          <h2
            className="text-[32px] md:text-[56px] mb-6 md:mb-10"
            style={{ fontFamily: "Quantum rise" }}
          >
            Leadership
          </h2>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row bg-[#c3c3c6] rounded-lg md:h-96">
        <div className="md:w-[50vw] py-14 pl-5 xl:pl-20 pr-20 md:pr-0">
          <h1 className="text-[24px] md::text-[40px] font-semibold">
            Bhuwan Lodha
          </h1>
          <h3 className="text-2xl italic my-5">CEO - AI Divison</h3>
          <p className="text-base xl:text-lg">
            Summary: Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum is simply dummy text
            of the printing and typesetting.
          </p>

          <a href="https://linkedin.com/in/" target="blank" className="">
            <img src={linkedin} alt="linkedin" className="mt-5" />
          </a>
        </div>
        <div className="md:w-[50vw] flex items-end justify-center md:justify-end">
          <img
            src={dummyImage1}
            alt="person"
            className="h-[350px] md:h-[450px] "
          />
        </div>
      </div>
    </div>
  );
};

// Custom Next Arrow
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 right-4 cursor-pointer z-10"
      style={{ transform: "translateY(-100%)", marginTop: "10px" }}
    >
      <img src={swipeRight} alt="next" onClick={onClick} />
    </div>
  );
};

// Custom Prev Arrow
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute top-0 right-20 cursor-pointer z-10"
      style={{ transform: "translateY(-100%)", marginTop: "10px" }}
    >
      <img src={swipeLeft} alt="prev" onClick={onClick} />
    </div>
  );
};

export default Leadership;
