import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion } from "framer-motion";
import GetInTouch from "../components/Contact/GetInTouch";
import Landing from "../components/Contact/Landing";
import Map from "../components/Contact/Map";

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const Contact = () => {
  const [animationStates, setAnimationStates] = useState({
    map: false,
    getInTouch: false,
  });

  const lastScrollY = useRef(0);
  const viewportHeight = useRef(window.innerHeight);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    viewportHeight.current = window.innerHeight;

    if (scrollTop > lastScrollY.current) {
      // Scroll down

      if (
        scrollTop + viewportHeight.current >=
        document.getElementById("get-in-touch").offsetTop
      ) {
        setAnimationStates((prev) => ({ ...prev, getInTouch: true }));
      }
    } else {
      // Scroll up
      if (
        scrollTop + viewportHeight.current <
        document.getElementById("get-in-touch").offsetTop
      ) {
        setAnimationStates((prev) => ({ ...prev, getInTouch: false }));
      }
    }

    lastScrollY.current = scrollTop;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <Landing />

      {/* <motion.div
        id="map"
        initial="hidden"
        animate={animationStates.map ? "visible" : "hidden"}
        variants={fadeInUp}
      > */}
      <Map />
      {/* </motion.div> */}

      <motion.div
        id="get-in-touch"
        initial="hidden"
        animate={animationStates.getInTouch ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <GetInTouch />
      </motion.div>
    </div>
  );
};

export default Contact;
