import React from "react";
import { useNavigate } from "react-router-dom";
import joinTeam from "../../assets/images/Join Our Team.png";

const JoinTeam = () => {
  const navigate= useNavigate();
  let timeoutRef;

  const handleButtonClick = () => {
    // Navigate to /about
    navigate("/contact");
    // Add hash for scrolling
    timeoutRef = setTimeout(() => {
      window.location.hash = "#contact-landing"; // Scroll to the specific section
      clearTimeout(timeoutRef)
    }, 50); // Use a timeout to give React Router time to navigate
  };
  return (
    <div className="my-14 md:my-28 px-5 md:px-36">
      <div className="flex flex-col md:flex-row-reverse space-y-8 md:space-y-0 md:space-x-10">
        <div className="text-[32px] md:text-[56px] leading-[40px] md:hidden" style={{fontFamily: 'Quantum rise'}}>Join Our Team</div>
        <div className="w-full md:w-[50vw] flex justify-center">
          <img
            src={joinTeam}
            alt="Mahindra Group Home"
            className="md:w-[40vw] object-cover"
          />
        </div>
        <div className="space-y-8 mt-5 w-full md:w-[50vw]">
          <div className="text-[32px] md:text-[56px] leading-[60px] mb-10 md:flex hidden" style={{fontFamily: 'Quantum rise'}}>
            Join Our Team
          </div>
          <div className="text-base md:text-lg leading-7 md:w-[80%]">
            Are you ready to make an impact with cutting-edge AI technology? At
            Mahindra's AI division, we're always looking for talented
            professionals who are passionate about innovation and excellence.
            Want to be part of a team that is shaping the future of AI?
          </div>
          <button
            className="text-white text-[16px] px-5 py-2 rounded-md transition-transform duration-300 hover:scale-105"
            style={{ background: "#dd052b" }}
            onClick={handleButtonClick}
          >
            WORK WITH US
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinTeam;
