import React from "react";
import { useNavigate } from "react-router-dom";
import Careers from "../../assets/images/icon Careers.png";
import Partnerships from "../../assets/images/icon Partnerships.png";

const JoinUs = () => {
  const navigate = useNavigate();
  let timeoutRef;

  const handleButtonClick = () => {
    // Navigate to /about
    navigate("/join-us");
    // Add hash for scrolling
    timeoutRef = setTimeout(() => {
      window.location.hash = "#join-us-landing"; // Scroll to the specific section
      clearTimeout(timeoutRef)
    }, 50); // Use a timeout to give React Router time to navigate
  };
  return (
    <div className="py-16 px-5 md:px-36 bg-[#f4f4f4]">
      <h1 className="text-[32px] md:text-[56px] font-bold" style={{fontFamily: 'Quantum rise'}}> Join Us</h1>
      <p className="text-base font-thin mt-5 mb-10">
        Discover more about how you can be part of our journey or partner with
        us today
      </p>
      <div className="flex flex-col md:flex-row md:justify-between space-y-2 md:space-y-0 md:space-x-3">
        {/* careers */}
        <div className="flex flex-col p-16 border-[1px] border-slate-300 rounded-lg  md:w-[40vw]">
          <img src={Careers} alt="careers" className="w-16 h-16 -ml-10" />
          <h2 className="text-3xl font-bold my-5 -mt-6">Careers</h2>
          <p className="text-base font-thin">
            Explore exciting opportunities to join our team and contribute to
            cutting-edge AI innovations. Discover job openings and learn about
            our dynamic work environment.
          </p>
          <div onClick={handleButtonClick} className="flex cursor-pointer justify-center mt-10 w-36 uppercase text-sm font-semibold py-3 bg-[#E41636] text-white rounded-lg">
            Work with us
          </div>
        </div>
        {/* Partnership */}
        <div className="flex flex-col p-16 border-[1px] border-slate-300 rounded-lg  md:w-[40vw]">
          <img
            src={Partnerships}
            alt="Partnerships"
            className="w-16 h-16 -ml-10"
          />
          <h2 className="text-3xl font-bold my-5 -mt-6">Partnerships</h2>
          <p className="text-base font-thin">
            We seek collaborative opportunities with technology providers and
            industry leaders. Explore ways we can work together to drive
            impactful Al solutions.
          </p>
          <div onClick={handleButtonClick} className="flex cursor-pointer justify-center mt-10 w-36 uppercase text-sm font-semibold py-3 bg-[#E41636] text-white rounded-lg">
            Get in touch
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
