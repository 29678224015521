import React from "react";
import aboutImage from "../../assets/images/Mahindra_Group_home.png";
import risingBeam from "../../assets/images/Rising_Beam_brand_Pillers.png";

const AboutMahindra = () => {
  return (
    <div className="px-4 mb-8 md:px-24 space-y-10">
      <div className="">
        {/* Section Title */}
        <div className="text-lg mb-4 md:mb-7" style={{ color: "#dd052b" }}>
          ABOUT
        </div>
        <div className="text-[32px] md:text-[56px] mb-6 md:mb-10" style={{fontFamily: 'Quantum rise'}}>Mahindra Group</div>

        {/* Content Section */}
        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10">
          {/* Image Section */}
          <div className="w-full md:w-[50vw] flex justify-center">
            <img
              src={aboutImage}
              alt="Mahindra Group Home"
              className="w-full md:w-[40vw] object-cover"
            />
          </div>

          {/* Text Section */}
          <div className="space-y-6 md:space-y-8 mt-4 md:mt-5 w-full md:w-[50vw]">
            <div className="text-[14px] md:text-[16px] leading-6 md:leading-7 md:w-[90%]">
              Founded in 1945, the Mahindra Group is one of the largest and most
              admired multinational federations of companies with 260,000
              employees in over 100 countries. It enjoys a leadership position
              in farm equipment, utility vehicles, information technology, and
              financial services in India and is the world's largest tractor
              company by volume. It has a strong presence in renewable energy,
              agriculture, logistics, hospitality, and real estate.
            </div>

            <div className="">
              Our Purpose: Drive positive change in the lives of our
              communities. Only when we enable others to rise will we rise.
            </div>

            <div className="text-[#dd052b] font-semibold">#TogetherWeRise</div>
          </div>
        </div>
      </div>

      <div className="relative w-full bg-white p-8 border border-gray-300 rounded-lg mx-auto shadow-md">
        <div className="xl:mx-36">
          <div className="flex flex-col md:flex-row justify-around">
            <div style={{fontFamily: 'Quantum rise'}}>
              <div className="text-[24px] md:text-[40px] font-bold text-black mb-4 text-start md:text-end">
                Our
              </div>
              <div className="text-[24px] md:text-[40px] font-bold text-black mb-6">
                Brand Pillars
              </div>
            </div>
            <ul className="list-disc ml-6 space-y-2 text-lg text-black">
              <li>Rise for a more equal world.</li>
              <li>Rise to be future-ready.</li>
              <li>Rise to create value.</li>
            </ul>
          </div>
        </div>
        <img
          src={risingBeam}
          alt="red line"
          className="absolute bottom-0 right-6 w-32"
        />
      </div>
    </div>
  );
};

export default AboutMahindra;
