import React from "react";
import { useNavigate } from "react-router-dom";
import aboutImage from "../../assets/images/Mahindra_Group_home.png";

const About = () => {
  const navigate= useNavigate()
  let timeoutRef;

  const handleButtonClick = () => {
    // Navigate to /about
    navigate("/about");
    // Add hash for scrolling
    timeoutRef = setTimeout(() => {
      window.location.hash = "#vision"; // Scroll to the specific section
      clearTimeout(timeoutRef); // Clear timeout after it's executed
    }, 50);
  };
  return (
    <div className="my-8 md:my-16 px-4 md:px-36">
      <div className="border-t">
        {/* Section Title */}
        <div className="text-lg my-4 md:my-7" style={{ color: "#dd052b" }}>
          ABOUT
        </div>
        <div className="text-[32px] md:text-[56px] mb-6 md:mb-10" style={{fontFamily: 'Quantum rise'}}>Mahindra Group</div>

        {/* Content Section */}
        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-10">
          {/* Image Section */}
          <div className="w-full md:w-[50vw] flex justify-center">
            <img
              src={aboutImage}
              alt="Mahindra Group Home"
              className="w-full md:w-[40vw] object-cover"
            />
          </div>

          {/* Text Section */}
          <div className="space-y-6 md:space-y-8 mt-4 md:mt-5 w-full md:w-[50vw]">
            <div className="text-[14px] md:text-[16px] leading-6 md:leading-7 md:w-[90%]">
              Founded in 1945, the Mahindra Group is one of the largest and most
              admired multinational federations of companies with 260,000
              employees in over 100 countries. It enjoys a leadership position
              in farm equipment, utility vehicles, information technology, and
              financial services in India and is the world's largest tractor
              company by volume. It has a strong presence in renewable energy,
              agriculture, logistics, hospitality, and real estate.
            </div>
            <button
              className="text-white text-[14px] md:text-[16px] px-5 py-2 rounded-md transition-transform duration-300 hover:scale-105"
              style={{ background: "#dd052b" }}
              onClick={handleButtonClick}
            >
              LEARN MORE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
