import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import videoSource from "../../assets/videos/Home_Banner_Video_720p.mp4";

const LandingPage = () => {
  const [borderWidth, setBorderWidth] = useState(15); // Initial border width
  const [animate, setAnimate] = useState(false);
  const navigate = useNavigate();
  const lastScrollY = useRef(0); // To keep track of the last scroll position

  // Function to handle scroll event
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;

    // Set border width based on scroll position
    if (scrollTop < viewportHeight) {
      setBorderWidth(50); // Trigger animation when within the viewport
      setAnimate(true);
    } else {
      setBorderWidth(15); // Reset border width when scrolled beyond 100vh
      setAnimate(false);
    }

    // Scroll down automatically if the user is scrolling down past 10vh
    if (
      scrollTop > 0.1 * viewportHeight &&
      scrollTop < viewportHeight &&
      lastScrollY.current < scrollTop // Only trigger scroll if user scrolls down
    ) {
      window.scrollTo({
        top: viewportHeight, // Scroll to the bottom of the section
        behavior: "smooth",  // Smooth scroll
      });
    }

    // Update last scroll position
    lastScrollY.current = scrollTop;
  }, []);

  useEffect(() => {
    // Initial animation on page load
    setAnimate(true);

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const element = document.querySelector(".main-diagonal");
    if (animate && element) {
      // Force a reflow to restart the animation
      element.classList.remove("animate-border");
      void element.offsetWidth; // Trigger a reflow
      element.classList.add("animate-border");
    }
  }, [animate]);

  return (
    <div id="home-landing">
      {/* Video background */}
      <div className="h-[92vh] relative">
        <video autoPlay muted loop className="h-full w-full object-cover">
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Diagonal Section with dynamic border */}
      <div
        className="main-diagonal"
        style={{ "--border-width": `${borderWidth}%` }}
      ></div>

      {/* Text Content */}
      <div className="flex h-full absolute top-14 z-10 flex-col mt-14 md:mt-8 lg:mt-20 xl:mt-8 items-center px-5 md:px-10">
        <div>
          <h1
            className="text-[32px] md:text-[72px]"
            style={{ color: "#E41636", fontFamily: "Quantum rise" }}
          >
            Mahindra AI
          </h1>
          <p className="text-[24px] md:text-[32px]">
            Where AI Meets Innovation
          </p>
          <button
            className="text-white mt-5 text-[14px] md:text-[16px] px-5 py-2 rounded-md"
            style={{ background: "#dd052b" }}
            onClick={() => navigate("/about")}
          >
            HOW WE WORK
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
