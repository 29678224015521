import React from "react";

const CeoMessage = () => {
  return (
    <div className="h-[45vh] flex justify-center items-center text-4xl">
      Message
    </div>
  );
};

export default CeoMessage;
