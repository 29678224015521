import React from "react";
import { useNavigate } from "react-router-dom";
import partnerWithUs from "../../assets/images/Partner with us.png";

const PartnerWithUs = () => {
  const navigate = useNavigate();
  let timeoutRef;

  const handleButtonClick = () => {
    // Navigate to /about
    navigate("/contact");
    // Add hash for scrolling
    timeoutRef = setTimeout(() => {
      window.location.hash = "#contact-landing"; // Scroll to the specific section
      clearTimeout(timeoutRef);
    }, 50); // Use a timeout to give React Router time to navigate
  };
  return (
    <div
      className="py-14 md:py-28 px-5 md:px-36"
      style={{ backgroundColor: "#f4f4f4" }}
    >
      <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-10">
        <div
          className="text-[32px] md:text-[56px] pr-5 md:pr-0 leading-[40px] md:hidden"
          style={{ fontFamily: "Quantum rise" }}
        >
          Partner with us
        </div>
        <div className="w-full md:w-[50vw] flex justify-center">
          <img
            src={partnerWithUs}
            alt="Mahindra Group Home"
            className="md:w-[40vw] object-cover"
          />
        </div>
        <div className="space-y-8 mt-5 w-full md:w-[50vw]">
          <div
            className="text-[32px] md:text-[56px] md:pr-5 leading-[60px] mb-10 md:flex hidden"
            style={{ fontFamily: "Quantum rise" }}
          >
            Partner with us
          </div>
          <div className="text-base md:text-lg leading-7 md:w-[85%]">
            We believe in the power of collaboration to drive AI innovation
            forward. Whether you're a technology provider, research institution,
            or industry leader, we welcome the oppurtunity to explore mutually
            beneficial partnerships. Get in Touch: Want to discuss potential
            collaborations and discover how we can achieve great things
            together.
          </div>
          <button
            className="text-white text-[16px] px-5 py-2 rounded-md transition-transform duration-300 hover:scale-105"
            style={{ background: "#dd052b" }}
            onClick={handleButtonClick}
          >
            GET IN TOUCH
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
