import React from "react";
import iconAddress from "../../assets/images/icon_address.png";
import iconMail from "../../assets/images/icon_mail.png";
import iconPhone from "../../assets/images/icon_phone.png";

const GetInTouch = () => {
  return (
    <div className="mt-20 space-y-4">
      <div className="flex items-center text-center md:items-start flex-col pb-6 text-[32px] md:text-[40px] px-0 md:px-32" style={{fontFamily: 'Quantum rise'}}>
        Get in Touch
      </div>
      <div className="relative">
        {/* Half Black Section */}
        <div
          className="h-[85%] md:h-[50%] w-full absolute bottom-0 left-0 border-b border-gray-600"
          style={{ background: "#080808" }}
        ></div>

        <div className="relative z-10 pt-12 md:pt-0">
          <h2 className="text-center text-3xl font-semibold mb-8 text-white">
            {/* Get in Touch */}
          </h2>

          <div className="flex flex-col pt-10 md:pt-0 md:flex-row items-center justify-center gap-14 md:gap-8">
            {/* Card for Phone */}
            <div className="relative bg-[#f4f4f4] border-t text-center p-6 w-[90vw] md:w-[25vw] h-[14rem] rounded-lg shadow-lg md:-translate-y-0 -translate-y-1/2">
              {/* Icon */}
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                <img src={iconPhone} alt="phone icon" className="w-14" />
              </div>
              <p className="text-lg font-medium text-gray-800 mt-12">Phone</p>
              <p className="text-gray-600">+91 XXXXX XXXXX</p>
            </div>

            {/* Card for Email */}
            <div className="relative bg-[#f4f4f4] border-t text-center p-6 -mt-28 md:mt-0 w-[90vw] md:w-[25vw] h-[14rem] rounded-lg shadow-lg">
              {/* Icon */}
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                <img src={iconMail} alt="mail icon" className="w-14" />
              </div>
              <p className="text-lg font-medium text-gray-800 mt-12">Email</p>
              <p className="text-gray-600">xxxxxxxxxxx@mahindra.com</p>
            </div>

            {/* Card for Address */}
            <div className="relative bg-[#f4f4f4] border-t text-center p-6 w-[90vw] md:w-[25vw] h-[14rem] rounded-lg shadow-lg">
              {/* Icon */}
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                <img src={iconAddress} alt="address icon" className="w-14" />
              </div>
              <p className="text-lg font-medium text-gray-800 mt-12">Address</p>
              <p className="text-gray-600">XXX, YYY</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
