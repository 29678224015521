import React from "react";
import banner from "../../assets/images/Banner.png"; // Ensure the path to the banner is correct

const Landing = () => {
  return (
    <div
      className="relative h-[35vh] bg-cover bg-center"
      id="about-landing"
      style={{ backgroundImage: `url(${banner})` }}
    >
      {/* Overlay Text */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
        <h1 className="text-[32px] md:text-[72px] font-semibold" style={{ color: "#e1002a", fontFamily: 'Quantum rise' }}>
          About Us
        </h1>
        <p className="text-xl md:w-[50%] mt-2" style={{ color: "#cfd0d2" }}>
          Join us as we drive innovation and shape the future with cutting edge
          technology.
        </p>
      </div>
    </div>
  );
};

export default Landing;
