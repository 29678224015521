import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import initialLogo from "../../assets/images/Mahindra -M.ai.png";
import maiGif from "../../assets/images/M_AI-New-Gif-Compressed-x24.gif";
import maiGifReverse from "../../assets/images/M_AI-New-Gif-Compressed-x24-reverse.gif";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true); // To show/hide navbar on scroll
  const [lastScrollY, setLastScrollY] = useState(0); // Store the last scroll position
  const [playGif, setPlayGif] = useState(false); // Control GIF play
  const [showInitialLogo, setShowInitialLogo] = useState(true); // Show the initial static logo
  const [playReverseGif, setPlayReverseGif] = useState(false); // Control reverse GIF
  const [gifPlayed, setGifPlayed] = useState(false); // Check if the GIF has been played
  const location = useLocation(); // Get current route path

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to check if the current route matches the link
  const isActive = (path) => {
    return location.pathname === path ? "font-bold" : "text-gray-700";
  };

  const controlNavbarAndGif = () => {
    const currentScrollY = window.scrollY;
    const viewportHeight = window.innerHeight;

    // Play GIF after scrolling 10vh and hide navbar after 70vh
    if (currentScrollY > 0.1 * viewportHeight && !gifPlayed) {
      setPlayGif(true);
      setShowInitialLogo(false); // Hide the static logo
      setTimeout(() => {
        setPlayGif(false); // Stop playing GIF after it ends but keep last frame visible
        setGifPlayed(true); // Mark GIF as played
      }, 3000); // Assuming 3 seconds for GIF to play
    }

    // Hide the navbar after scrolling 70vh
    if (currentScrollY > 0.7 * viewportHeight && gifPlayed) {
      // setShowNavbar(false);
    }

    // When the user scrolls back to the top (0vh)
    if (currentScrollY === 0) {
      // setShowNavbar(true);
      setGifPlayed(false); // Reset GIF played state once at the top
      setShowInitialLogo(false); // Hide the static logo when reverse GIF is playing
      setPlayReverseGif(true); // Play the reverse GIF when scrolling to the top
      setTimeout(() => {
        setPlayReverseGif(false); // Stop playing reverse GIF after it finishes
        setShowInitialLogo(true); // Show the static initial logo again
      }, 2000); // Assuming 2 seconds for reverse GIF to play
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbarAndGif);
    return () => {
      window.removeEventListener("scroll", controlNavbarAndGif);
    };
  }, [lastScrollY, gifPlayed]);

  return (
    <nav
      className={`md:p-5 md:flex justify-around border-b border-gray-400 bg-[#e4e4e4] transition-transform duration-300 ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      } fixed top-0 left-0 w-full z-50`}
    >
      {/* Logo Section */}
      <div className="flex items-center justify-between p-5 md:p-0">
        <a href="/">
          {/* Conditionally render the logo based on the scroll position */}
          {showInitialLogo ? (
            <img src={initialLogo} alt="Initial Logo" className="w-[180px]" />
          ) : playGif ? (
            <img src={maiGif} alt="Animated Logo" className="w-[180px]" />
          ) : playReverseGif ? (
            <img src={maiGifReverse} alt="Reverse Logo" className="w-[180px]" />
          ) : (
            // After the GIF has played, keep the GIF visible without resetting to initial state
            <img src={maiGif} alt="Final Frame of GIF" className="w-[180px]" />
          )}
        </a>

        {/* Hamburger Icon for Mobile and Tablet */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            {/* Hamburger Icon */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Menu Items - Full Menu for Desktop, Dropdown for Mobile */}
      <div
        className={`md:flex text-[16px] items-center md:w-2/3 justify-around p-5 md:p-0 bg-[#F4F4F4] md:bg-[#e4e4e4] ${
          isOpen ? "block" : "hidden"
        } md:block`}
      >
        <div className="mt-4 md:mt-0">
          <a
            href="/about"
            className={`hover:text-gray-600 block md:inline-block ${isActive(
              "/about"
            )}`}
          >
            About Us
          </a>
        </div>
        <div className="mt-4 md:mt-0">
          <a
            href="/join-us"
            className={`hover:text-gray-600 block md:inline-block ${isActive(
              "/join-us"
            )}`}
          >
            Join Us
          </a>
        </div>
        <div className="mt-4 md:mt-0">
          <a
            href="/contact"
            className={`hover:text-gray-600 block md:inline-block ${isActive(
              "/contact"
            )}`}
          >
            Contact
          </a>
        </div>
        <div className="mt-4 md:mt-0">
          <a
            href="/group-ceo-message"
            className={`hover:text-gray-600 block md:inline-block ${isActive(
              "/group-ceo-message"
            )}`}
          >
            Group CEO & MD's Message
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
